import { initAccordion } from "../../template-parts/blocks/accordions/script";
import { initAccordionTeasers } from "../../template-parts/blocks/accordion-teaser/script";
import { initGlobalSiteHero } from "../../template-parts/blocks/global-site-hero/script";
import { initHistorySlider } from "../../template-parts/blocks/history-slider/script";
import { initPostListWithFilter } from "../../template-parts/blocks/post-list-with-filter/script";
import { initProductIntro } from "../../template-parts/blocks/product-intro/script";
import { initSustainabilityCalculator } from "../../template-parts/blocks/sustainability-calculator/script";
import { initCo2Calculator } from "../../template-parts/blocks/co2-calculator/script";
import { initTestimonials } from "../../template-parts/blocks/testimonials/script";
import { whenEditorIsReady } from "./utils";

// these functions run on page load

/**
 * Reason: Mobile browsers treat vh unit differently than desktop ones
 * This function calculate vh unit in pixel, and set to css variable so it can be reused consistently
 */
const setVh = () => {
    // get actual 1vh value in pixel
    let vh = window.innerHeight * 0.01;
    // Set the value to the --vh variable at the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
};

setVh();
window.addEventListener("resize", setVh);

const blocksWithScript = {
    "acf/accordions": initAccordion,
    "acf/accordion-teaser": initAccordionTeasers,
    "acf/global-site-hero": initGlobalSiteHero,
    "acf/history-slider": initHistorySlider,
    "acf/sustainability-calculator": initSustainabilityCalculator,
    "acf/co2-calculator": initCo2Calculator,
    "acf/post-list-with-filter": initPostListWithFilter,
    "acf/product-intro": initProductIntro,
    "acf/testimonials": initTestimonials,
};

/**
 * Editor: Trigger the block's JS when added
 */
const triggerBlockScriptInEditor = () => {
    if (typeof wp === "undefined" || !wp.blocks) {
        return;
    }

    const { getBlocks, getClientIdsOfDescendants } =
        wp.data.select("core/block-editor");
    // Get current blocks client ids
    let blockList = getBlocks();

    wp.data.subscribe(() => {
        // Get new blocks client ids
        const newBlockList = getBlocks();
        // Quit if there is no change in the block list (e.g. when user just wrote new text)
        const blockListChanged = newBlockList !== blockList;
        if (!blockListChanged) {
            return;
        }

        const newBlockIds = newBlockList.map((block) => block.clientId);
        // The editor need some time to render after a block is added
        whenEditorIsReady()?.then(() => {
            const addedBlocks = newBlockIds.filter(
                (blockId) => !blockList.includes(blockId)
            );

            // scan through the list of added blocks, and trigger their corresponding scripts
            addedBlocks.forEach((addedBlockId) => {
                const innerBlockIds = getClientIdsOfDescendants([addedBlockId]);

                [addedBlockId, ...innerBlockIds].forEach((blockClientId) => {
                    triggerBlockScriptById(blockClientId);
                    addBlockChangeListener(blockClientId);
                });
            });

            // Update current block list with the new blocks for further comparison
            blockList = newBlockList;
        });
    });
};

/**
 * Trigger block script when its acf field value change
 * @param {string} blockClientId
 * @returns
 */
const addBlockChangeListener = (blockClientId) => {
    const { getBlockName } = wp.data.select("core/block-editor");
    const blockWrapper = document.querySelector(`#block-${blockClientId}`);
    const blockName = getBlockName(blockClientId);

    if (!Object.keys(blocksWithScript).includes(blockName)) {
        return;
    }

    const observer = new MutationObserver((list) => {
        // prevent the init function to get triggered too many times
        if (blockWrapper?.dataset["eventAdded"]) {
            return;
        }

        // A change in ACF field will rerender the block
        // rerender means the mutation list only contain two items: remove .acf-block-preview & add it again
        if (
            list.length === 2 &&
            list[0].removedNodes.length === 1 &&
            list[1].addedNodes.length === 1
        ) {
            blocksWithScript[blockName](blockWrapper);
        }
    });
    observer.observe(blockWrapper, {
        childList: true,
        subtree: true,
    });

    blockWrapper.setAttribute("data-event-added", true);
};

/**
 *
 * @param {String} blockClientId
 * @returns
 */
const triggerBlockScriptById = (blockClientId) => {
    const checkPreviewReadyInterval = setInterval(() => {
        if (typeof wp === "undefined" || !wp.blocks) {
            return;
        }

        const { getBlockName } = wp.data.select("core/block-editor");
        const blockName = getBlockName(blockClientId);
        if (!blocksWithScript[blockName]) {
            clearInterval(checkPreviewReadyInterval);
            return;
        }

        const blockWrapper = document.querySelector(
            `#block-${blockClientId} .acf-block-preview > *:first-child`
        );

        if (!blockWrapper) {
            return;
        }

        if (blockWrapper?.dataset["eventAdded"]) {
            return;
        }

        blocksWithScript[blockName](blockWrapper);
        clearInterval(checkPreviewReadyInterval);
        blockWrapper.setAttribute("data-event-added", true);
    }, 100);
};

triggerBlockScriptInEditor();

function composeFormNotes(formData) {
    const filterFields = {
        employees: "企业员工数量",
        industry: "公司行业",
        "protective[]": "工作服特点",
        "standards[]": "标准",
        "workwear_change_times[]": "更换频率",
        "current[]": "当前清洗方式",
        "customization[]": "是否需要定制",
        files: "下载文件",
        CITY: "城市",
        POSITION: "职位",
        SOURCE: "如何找到林斯特龙",
        MESSAGE: "备注",
    };

    let message = "";
    for (let field in filterFields) {
        if (field.indexOf("[]" != -1)) {
            if (formData.has(field)) {
                message += `${filterFields[field]}: ${formData
                    .getAll(field)
                    .join(", ")};\n`;
            }
        } else {
            if (formData.has(field)) {
                message += `${filterFields[field]}: ${formData.get(field)};\n`;
            }
        }
    }

    return message;
}

/**
 * Needs HTML Forms WP plugin to operate
 */
function initFormTracking() {
    const forms = document.querySelectorAll("form.hf-form");

    forms.forEach((form) => {
        form.addEventListener("submit", () => {
            const formData = new FormData(form);

            // send form data to Jing
            const trackingEventData = {
                name: formData.get("NAME"),
                mobile: formData.get("PHONE"),
                email: formData.get("EMAIL"),
                company: formData.get("COMPANY"),
                notes: composeFormNotes(formData),
            };
            window.trak && trak.custom_event("trackToUser", trackingEventData);

            // send tracking event to Baidu & 360
            window._hmt &&
                _hmt.push(["_trackEvent", "form", "submit", "success"]);
            window._qha &&
                _qha("send", {
                    et: 31,
                    order: [{ id: new Date().valueOf(), orderType: "1" }],
                });
        });
    });
}

initFormTracking();

const restrictAcfColorPallette = () => {
    if (typeof acf === "undefined") {
        return;
    }
    acf.add_filter("color_picker_args", (args) => {
        // set custom Lindstrom pallette
        args.palettes = [
            "#ffffff",
            "#f4f4f4",
            "#f2f2f2",
            "#e5e5e5",
            "#dddddd",
            "#999999",
            "#555555",
            "#222222",
            "#ff7900",
            "#ca005d",
            "#e52330",
            "#7c109a",
            "#7ab800",
            "#0094b3",
            "#0074cc",
            "#003f72",
        ];
        return args;
    });
};

restrictAcfColorPallette();

/*
 * Video tag need `poster` attribute to load poster image properly.
 * For some reason, only `data-poster-webp` or `data-poster-img` rendered
 */
document.addEventListener("DOMContentLoaded", () => {
    const videoEls = document.querySelectorAll("video");
    videoEls.forEach((videoEl) => {
        // skip if video poster image load properly
        if (videoEl.getAttribute("poster")) {
            return;
        }
        // check data-poster-webp, fallback to data-poster-img
        let posterUrl = videoEl.getAttribute("data-poster-webp");
        if (!posterUrl) {
            posterUrl = videoEl.getAttribute("data-poster-image");
        }

        if (posterUrl) {
            videoEl.setAttribute("poster", posterUrl);
        }
    });
});

/**
 * Customize interaction for floating widget telephone button
 */
document.addEventListener("DOMContentLoaded", () => {
    const floatingWidgetEl = document.querySelector(".floating-widget");
    if (!floatingWidgetEl) {
        return;
    }

    // find all buttons with phone number (link start with tel:)
    const telEls = floatingWidgetEl.querySelectorAll(
        '.floating-widget__link[href^="tel:"]'
    );
    telEls.forEach((telEl) => {
        // catch the click event on desktop, and show the number instead of making the call
        telEl.addEventListener("click", (event) => {
            if (window.innerWidth <= 480) {
                return;
            }
            event.preventDefault();

            // skip if tooltip is already visible
            if (telEl.querySelector(".link__tooltip")) {
                return;
            }

            // add link tooltip when clicked
            const tooltipEl = document.createElement("div");
            tooltipEl.classList.add("link__tooltip");
            // get the phone number after tel: to set as tooltip content
            tooltipEl.innerHTML = `<span>${telEl
                .getAttribute("href")
                .substr(4)}</span>`;
            telEl.append(tooltipEl);

            // add a close button to the tooltip
            const tooltipCloseEl = document.createElement("span");
            tooltipCloseEl.classList.add("tooltip__close");
            tooltipCloseEl.innerHTML = "x";
            tooltipEl.prepend(tooltipCloseEl);

            tooltipCloseEl.addEventListener(
                "click",
                (tooltipCloseClickEvent) => {
                    tooltipCloseClickEvent.preventDefault();
                    tooltipCloseClickEvent.stopPropagation();
                    // remove the tooltip element when close button clicked
                    tooltipEl.remove();
                }
            );
        });
    });
});
